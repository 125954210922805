import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-modal-asociar-aeroplanta',
  templateUrl: './modal-asociar-aeroplanta.component.html',
  styleUrls: ['./modal-asociar-aeroplanta.component.css']
})
export class ModalAsociarAeroplantaComponent {

  @Input() mensaje: string;

  constructor(private authService: AuthService) { }

  onCerrarSesionClick() {
    this.authService.logout();
  }

}