import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { AccionLogin } from 'src/app/shared/enums/AccionLogin';
import { UsuarioService } from 'src/app/account/usuario.service';
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent {

  enumAccionLogin = AccionLogin;
  accionLoginSubscription: Subscription;
  accionLogin: AccionLogin;

  username: String;
  nombreCompleto: String;
  email: String;
  dni: String;
  roles: String;

  TipoUsuario = TipoUsuario;

  constructor(public authService: AuthService, private usuarioService: UsuarioService) { 
    this.accionLoginSubscription = this.authService.authAccionLogin$.subscribe(accionLogin => {
      if(accionLogin != null) 
        this.accionLogin = 2;
        this.username = usuarioService.getUsername();
        this.nombreCompleto = usuarioService.getNombresCompletosUsuario();
        //this.email = usuarioService.getEmailUsuario();
        this.dni = usuarioService.getDniUsuario();        
        this.roles = usuarioService.getMostrarRoles();
    });
  }

  obtenerNombreRoles(n:number) {
    return TipoUsuario[n]
  }

  onCerrarSesionClick() {
    this.authService.logout();
  }

}
