import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { SharedModule } from './shared/shared.module';
import { DespachoModule } from './despacho/despacho.module';
import { RanchoModule } from './rancho/rancho.module';
import { HttpErrorHandler } from './http-error-handler.service';
import { MessageService } from './shell/message.service';
import { NgbModule, NgbDateNativeAdapter, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManagementModule } from './management/management.module';
import { PreciosModule } from './precios/precios.module';
import { ModalConfirmComponent } from './shared/generic-modals/modal-confirm.component';
import localeEsAR from '@angular/common/locales/es-AR';
import { ModalOkComponent } from './shared/generic-modals/modal-ok.component';
import { AerovaleDetalleComponent } from './despacho/aerovale-detalle/aerovale-detalle.component';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { FacturacionModule } from './facturacion/facturacion.module';
import { StockModule} from './stock/stock.module';
import { AbmModule} from './abm/abm.module';
import { ToastModule } from 'primeng/toast';
import {NgxPaginationModule, PaginatePipe} from 'ngx-pagination';
import { DatePipe } from '@angular/common';
import { ReportesModule } from './reportes/reportes.module';
import { CierreTurnoModule } from './cierreTurno/cierre-turno.module';
import { AjusteManualCombustibleComponent } from './stock/listados/ajuste-manual-combustible/ajuste-manual-combustible.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { ControlCreditoModule } from './controlCredito/control-credito.module';
import { CookiesInterceptor } from './core/interceptors/cookies.interceptor';

registerLocaleData(localeEsAR, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent,
    ModalConfirmComponent,
    ModalOkComponent,
  ],
  imports: [
    BrowserModule,
    NgxBarcode6Module,
    HttpClientModule,
    HomeModule,
    DespachoModule,
    ManagementModule,
    RanchoModule,
    AppRoutingModule,
    ShellModule,
    SharedModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    FacturacionModule,
    ReportesModule,
    StockModule,
    AbmModule,
    ToastModule,
    NgxPaginationModule,
    NgbModule,
    PreciosModule,
    CierreTurnoModule,
    ControlCreditoModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    PaginatePipe,
    DatePipe,
    NgbDateNativeAdapter,
    HttpErrorHandler,
    NgbActiveModal,
    MessageService,
    { provide: LOCALE_ID, useValue: "es-AR" },  
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookiesInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    ModalConfirmComponent,
    ModalOkComponent,
    AerovaleDetalleComponent,
    AjusteManualCombustibleComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
