import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from "../../shared/base.service";
import { TipoUsuario } from 'src/app/shared/enums/tipoUsuario';
import { Usuario } from 'src/app/shared/models/despacho/Usuario';
import { UsuarioService } from 'src/app/account/usuario.service';
import { AccionLogin } from 'src/app/shared/enums/AccionLogin';
import { MessageService } from 'src/app/shell/message.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  private _authAccionLoginSource = new BehaviorSubject<AccionLogin>(null);
  private _authProcesandoLoginSource = new BehaviorSubject<boolean>(false);
  private _usuarioSource = new BehaviorSubject<Usuario>(null);

  authNavStatus$ = this._authNavStatusSource.asObservable();
  authAccionLogin$ = this._authAccionLoginSource.asObservable();
  usuario$ = this._usuarioSource.asObservable();
  procesandoLogin$ = this._authProcesandoLoginSource.asObservable();

  private _usernameHeaderValue: string;
  private bffUrl = environment.bffUrl;

  constructor(
    private http: HttpClient, 
    private usuarioService: UsuarioService, 
    private messageService: MessageService) { }

  login(): void {  
    window.location.href = `${this.bffUrl}/.auth/login`;  
  }

  logout(): void {
    this.http.delete(`${this.bffUrl}/.auth/end-session`, { withCredentials: true })
      .subscribe((r) => {
        console.log(r);
        location.assign("#");
      });
  }  

  getUserInfo(): Observable<any> {  
    return this.http.get(`${this.bffUrl}/.auth/me`, { withCredentials: true });  
  }

  processLogin(userInfo) {
    this._authProcesandoLoginSource.next(true);

    const usuario = new Usuario();
    usuario.nombresCompletos = userInfo.name;
    usuario.email = userInfo.email;
    usuario.userName = userInfo.preferred_username;

    this.loginUsuarioPad(usuario);
  }

  loginUsuarioPad(usuario){
    this.usuarioService.loginUsuario(usuario).subscribe(
      usuarioAccionDto => {
        usuario = usuarioAccionDto.usuarioDTO;

        usuario.roles = [5, 1];
        
        // Aqui vuelven los roles. Tomarlos y asignarlos a usuario.
        usuario.sRoles = this.usuarioService.convertirRolesIntEnRolesString(usuario.roles);

        this._usuarioSource.next(usuario);
        this._usernameHeaderValue = usuario.userName;
        this._authAccionLoginSource.next(2);
        this._authNavStatusSource.next(this.isAuthenticated());
        this._authProcesandoLoginSource.next(false);
      },
      (err) => {
        this.messageService.showErrorMessage(err.message + "\n" + err.description);
        console.log(err);
        this._authProcesandoLoginSource.next(false);
        this._authNavStatusSource.next(this.isAuthenticated());
      });
  }

  hasRole(...roles: TipoUsuario[]): boolean {
    var hasRole = this.userRoles.some(r => roles.indexOf(TipoUsuario[r]) !== -1);

    return hasRole;
  }

  isAuthenticated(): boolean {
    // return this._user != null && !this._user.expired;
    return true;
  }

  get userRoles(): string[] {
    return this.usuarioService.getArrayStringRoles();
  }

  get usernameHeaderValue(): string {
    if( ! this._usernameHeaderValue ) return "";
    return this._usernameHeaderValue;
  }
}
