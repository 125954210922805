import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})

export class SharedService {

  private changeAeroplanta: Subject<boolean> = new Subject;
  private managerState: Subject<boolean> = new Subject;

  /**
   * Genera un popup de impresión de "printContents"
   * @param printContents el template a imprimir
   */
  print(printContents: string): void {
    let popupWin;
    // printContents = document.getElementById('aerovalePrint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
          <html>
            <head>
              <title>Aerovale</title>
              <link rel="stylesheet" type="text/css" href="../../../assets/css/bootstrap.min.css">
              <link rel="stylesheet" type="text/css" href="../../../assets/css/print.css">
              <style>
              </style>
            </head>
            <body onload="window.print();window.close()"><div class="card">${printContents}</div></body>
          </html>`
    );
    popupWin.document.close();
  }

  /**
   * Genera un popup de impresión de "printContents"
   * @param printContents el template a imprimir
   */
   print2(printContents: string): void {
    let popupWin;
    // printContents = document.getElementById('aerovalePrint').innerHTML;
    popupWin = window.open();
    popupWin.document.open();
    popupWin.document.write(`
          <html>
            <head>
              <title>Remito</title>
              <link rel="stylesheet" type="text/css" href="../../../assets/css/bootstrap.min.css">
              <link rel="stylesheet" type="text/css" href="../../../assets/css/print.css">
              <style>
              </style>
            </head>
            <body onload="window.print();window.close()"><div class="card">${printContents}</div></body>
          </html>`
    );
    popupWin.document.close();
  }

  /**
   * Verifica que el cuit ingresado pase la prueba con el dígito verificador
   * @param cuit String a verificar
   */
  verificarCUIT(cuit: string): boolean {
    let esCuit = false;
    var vec = new Array(10);
    let cuit_rearmado = "";
    for (let i = 0; i < cuit.length; i++) {
      let caracter = cuit.charAt(i);
      if (caracter.charCodeAt(0) >= 48 && caracter.charCodeAt(0) <= 57) {
        cuit_rearmado += caracter;
      }
    }
    cuit = cuit_rearmado;
    if (cuit.length != 11) {  // si no estan todos los digitos 
      esCuit = false;
    } else {
      let x = 0;
      let dv = 0
      // Multiplico los dígitos. 
      vec[0] = (cuit.charCodeAt(0) - 48) * 5;
      vec[1] = (cuit.charCodeAt(1) - 48) * 4;
      vec[2] = (cuit.charCodeAt(2) - 48) * 3;
      vec[3] = (cuit.charCodeAt(3) - 48) * 2;
      vec[4] = (cuit.charCodeAt(4) - 48) * 7;
      vec[5] = (cuit.charCodeAt(5) - 48) * 6;
      vec[6] = (cuit.charCodeAt(6) - 48) * 5;
      vec[7] = (cuit.charCodeAt(7) - 48) * 4;
      vec[8] = (cuit.charCodeAt(8) - 48) * 3;
      vec[9] = (cuit.charCodeAt(9) - 48) * 2;

      // Suma cada uno de los resultado. 
      for (let i = 0; i <= 9; i++) {
        x += vec[i];
      }
      dv = (11 - (x % 11)) % 11;
      if (dv == (cuit.charCodeAt(10) - 48)) {
        esCuit = true;
      }
    }

    return esCuit;
  }

  /**
   * Convierte un date a string aceptado por backend (sin información de zona horaria)
   * @param date Date a convertir
   */
  dateToFormattedString(date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const day = date.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const hours = date.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const minutes = date.getMinutes().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const seconds = date.getSeconds().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  formatDensidad(event): string{
    var valorTexto = event.target.value;
      valorTexto = ("" + valorTexto).replace(".", "");
      var valorNumero = (Number(valorTexto) * 10) + Number(event.key);
      valorNumero = valorNumero / 1000;
      var textoSalida = valorNumero.toFixed(3);
      let indice = textoSalida.indexOf('.');
      let cadenaInicial = textoSalida.substring(-1, indice);
      let cadenaFinal = textoSalida.substr(-3);
      if (cadenaInicial.length == 2) {
        let digitosInicio = cadenaInicial.substr(1, 1);
        textoSalida = digitosInicio + '.' + cadenaFinal;
      }else{
        textoSalida = cadenaInicial + '.' + cadenaFinal;
      }
      return textoSalida;
  }

  resizeImage(file:File, maxWidth:number):Promise<Blob> {
    return new Promise((resolve, reject) => {
        let image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            let width = image.width;
            let height = image.height;
            
            if (width <= maxWidth) {
                resolve(file);
            }

            let newHeight = height * (maxWidth / width);
            let newWidth = maxWidth;

            let canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;

            let context = canvas.getContext('2d');

            context.drawImage(image, 0, 0, newWidth, newHeight);

            canvas.toBlob(resolve, file.type);
        };
        image.onerror = reject;
    });
  }

  get aeroplantaSeleccionada() {
    return this.changeAeroplanta.asObservable();
  }

  set aeroplantaSeleccionadaData(data: boolean) {
    this.changeAeroplanta.next(data);
  }

  get manager() {
    return this.managerState.asObservable();
  }

  set managerData(data: boolean) {
    this.managerState.next(data);
  }

  
  get aeroplantas() {
    return [
      {
        "id": "c7f25bc1-e038-4815-88b2-3a6f8945267a",
        "nombre": "Tucuman",
        "codigoAeroplanta": "TUC",
        "validada": true,
        "ciudadId": "ab343522-9f42-401a-8a52-58242b787edd",
        "provinciaId": "e55444c2-2f3f-484e-894c-4a73924e3cc2",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "b713f081-9554-400c-8079-db48d64591c4",
        "nombre": "Mar del Plata",
        "codigoAeroplanta": "MDQ",
        "validada": true,
        "ciudadId": "DFA4D18E-C292-48A9-915B-60D32C38EF85",
        "provinciaId": "44A8954C-4A74-4C07-B022-100000000001",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "a19a9cad-3240-479a-8a16-dc88743dba20",
        "nombre": "Gral Pico",
        "codigoAeroplanta": "GPO",
        "validada": true,
        "ciudadId": "6B416269-C7AD-4B4A-9546-9824811F23B6",
        "provinciaId": "14CC219B-DDB5-4857-B4EE-F5A08EA9C690",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "3855e9fb-dcb1-400e-8322-b4804e87fb1b",
        "nombre": "Venado Tuerto",
        "codigoAeroplanta": "VNO",
        "validada": true,
        "ciudadId": "04B51602-25BA-4838-914B-1662BFCDE54E",
        "provinciaId": "FBCB8E55-E45C-4837-8EE1-EFF2CE08DE04",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "ca371602-e8bf-48b4-aa4d-0ba1acffb07e",
        "nombre": "Santiago del Estero",
        "codigoAeroplanta": "SDE",
        "validada": true,
        "ciudadId": "a05c7b5a-59d7-4f76-a19a-eb55d3983ff7",
        "provinciaId": "a80c1129-5e89-4f4e-b432-c43bb426c705",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "50365ea3-b5c5-4ecd-9561-d5c59cc7d16b",
        "nombre": "Resistencia",
        "codigoAeroplanta": "RES",
        "validada": true,
        "ciudadId": "0464f4c9-02a0-4fa8-8b07-8ea17da6ffa4",
        "provinciaId": "34613d03-1215-45d5-a750-280a69b80ab1",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "b0478905-3ede-4de1-aa04-7eb90feed81f",
        "nombre": "Directo 2",
        "codigoAeroplanta": "DI2",
        "validada": true,
        "ciudadId": "03523505-398B-44F6-B6C3-73A118932682",
        "provinciaId": "FBCB8E55-E45C-4837-8EE1-EFF2CE08DE04",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "44a8954c-4a74-4c07-b022-100000000008",
        "nombre": "Bahia Blanca",
        "codigoAeroplanta": "BHI",
        "validada": true,
        "ciudadId": "44a8954c-4a74-4c07-b022-100000000002",
        "provinciaId": "44a8954c-4a74-4c07-b022-100000000001",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "323ff9b8-b84e-4d47-afbe-4e12a6e0d314",
        "nombre": "Trelew",
        "codigoAeroplanta": "REL",
        "validada": true,
        "ciudadId": "3FA7CDF8-3884-406D-AF7A-BE674680A67C",
        "provinciaId": "D0A3F374-52F5-4172-B448-71FA522A8171",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "44a8954c-4a74-4c07-b022-100000000009",
        "nombre": "Aeroparque",
        "codigoAeroplanta": "AEP",
        "validada": true,
        "ciudadId": "562d15dc-ca25-47b9-90f1-f834b9becc3b",
        "provinciaId": "0e3a6f70-dde4-4f77-b1b5-2f04f24421d1",
        "soloPAD": true,
        "habilitarFacturacion": true
      },
      {
        "id": "d7a5ec57-48cd-4836-a407-6bc72d9387ab",
        "nombre": "Reconquista",
        "codigoAeroplanta": "RCQ",
        "validada": true,
        "ciudadId": "750e7f31-d1c4-4caa-b0b2-02a4c6e75ec8",
        "provinciaId": "fbcb8e55-e45c-4837-8ee1-eff2ce08de04",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "1fb1da6d-9c08-4a01-b923-3ca466373aaf",
        "nombre": "Rio Grande",
        "codigoAeroplanta": "RGA",
        "validada": true,
        "ciudadId": "227957C7-1786-4621-B5B5-E22F6A933E7E",
        "provinciaId": "80759406-9388-4D3C-8041-DAF96DC4F3C2",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "aa543e62-d198-46af-8a05-8c69fe80769e",
        "nombre": "San Rafael",
        "codigoAeroplanta": "AFA",
        "validada": true,
        "ciudadId": "354A0B32-299F-4435-874B-19065C073332",
        "provinciaId": "970175CE-1160-44E6-9A47-E8ED4BFBE321",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "93bca353-0cd3-4285-9702-5cd538272f2c",
        "nombre": "Tandil",
        "codigoAeroplanta": "TDL",
        "validada": true,
        "ciudadId": "3F02E24B-A863-4484-AE88-A4A6160BB7DD",
        "provinciaId": "44A8954C-4A74-4C07-B022-100000000001",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "65902943-c586-4eb9-a347-4c09e725842b",
        "nombre": "Córdoba",
        "codigoAeroplanta": "COR",
        "validada": true,
        "ciudadId": "c53bd4b6-e14a-42e5-8722-77e4199e8bab",
        "provinciaId": "8112761a-9cfa-47fb-bf45-76488399c9e9",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "ee6f6c11-88cd-4106-bfc0-b3638e12d065",
        "nombre": "Mosconi",
        "codigoAeroplanta": "TTG",
        "validada": true,
        "ciudadId": "077D4241-31B4-4338-9158-83656AC04A9E",
        "provinciaId": "5F41C74E-BFBA-47AB-8842-C99FCB1A2A0F",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "996e5af0-36f2-43da-a2b7-f51b3c52658d",
        "nombre": "Jujuy",
        "codigoAeroplanta": "JUJ",
        "validada": true,
        "ciudadId": "b2e8340a-2006-4e91-9cbf-2e36a9a50c22",
        "provinciaId": "6642dfab-8d90-47b0-a826-f96de245fe43",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "caed405d-1d39-4019-b495-f4181fb2bb35",
        "nombre": "El Calafate",
        "codigoAeroplanta": "FTE",
        "validada": true,
        "ciudadId": "13286625-bc66-4b6a-83ee-7c3c4e10d09f",
        "provinciaId": "cea0fbb0-27ea-4133-9a25-a7834a7c03c9",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "3adbcff9-0d14-4353-a012-3315f656f910",
        "nombre": "Junin",
        "codigoAeroplanta": "JNI",
        "validada": true,
        "ciudadId": "86f7a090-012f-4363-84e1-536d00555e2c",
        "provinciaId": "44a8954c-4a74-4c07-b022-100000000001",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "8f772163-481e-478c-b38b-e80b7bbbc66f",
        "nombre": "Buenos Aires",
        "codigoAeroplanta": "DTO",
        "validada": true,
        "ciudadId": "562D15DC-CA25-47B9-90F1-F834B9BECC3B",
        "provinciaId": "44A8954C-4A74-4C07-B022-100000000001",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "cfb40fd2-3979-4d81-9f6f-9803ffb28e78",
        "nombre": "Campo De Mayo",
        "codigoAeroplanta": "CPO",
        "validada": true,
        "ciudadId": "562d15dc-ca25-47b9-90f1-f834b9becc3b",
        "provinciaId": "0e3a6f70-dde4-4f77-b1b5-2f04f24421d1",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "d08b076a-ddd4-4d7f-982f-7ce1c573a71b",
        "nombre": "La Plata",
        "codigoAeroplanta": "LPG",
        "validada": true,
        "ciudadId": "2a38a833-67a3-42e6-ac03-86f1ae701b1c",
        "provinciaId": "44a8954c-4a74-4c07-b022-100000000001",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "5f9bc223-7087-448d-8584-0d9fc9519a69",
        "nombre": "Esquel",
        "codigoAeroplanta": "EQS",
        "validada": true,
        "ciudadId": "795CC4DC-4CE0-4092-975F-BF27FEDEF245",
        "provinciaId": "D0A3F374-52F5-4172-B448-71FA522A8171",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "aa98da22-e67d-4758-a716-1b72f53edd37",
        "nombre": "Viedma",
        "codigoAeroplanta": "VDM",
        "validada": true,
        "ciudadId": "AB64FA53-33D5-4D4A-8AB9-428804BD2CAD",
        "provinciaId": "20EEA3D4-9B03-4D66-A218-4A5150156B20",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "a0b986e1-b536-4560-b1b8-0115f5c593a9",
        "nombre": "Plumerillo",
        "codigoAeroplanta": "MDZ",
        "validada": true,
        "ciudadId": "ED7D9172-BC6A-4248-A3F2-F6C803E00DCF",
        "provinciaId": "970175CE-1160-44E6-9A47-E8ED4BFBE321",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "760bd319-c168-4589-950e-0636caa6f937",
        "nombre": "Neuquén",
        "codigoAeroplanta": "NQN",
        "validada": true,
        "ciudadId": "A76E67C1-D24C-47A4-B5FE-3A804A3775CE",
        "provinciaId": "EF69144E-F754-4DD7-A3CA-8FD77FA7D185",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "7ce08330-3887-4368-a8ac-9bc9f4203332",
        "nombre": "Bariloche",
        "codigoAeroplanta": "BRC",
        "validada": true,
        "ciudadId": "827cb2a4-2c10-4b0d-99fc-fc8d84dd29e6",
        "provinciaId": "20eea3d4-9b03-4d66-a218-4a5150156b20",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "e632daf6-225f-40b0-acf6-0f0315ee4ee2",
        "nombre": "Rosario",
        "codigoAeroplanta": "ROS",
        "validada": true,
        "ciudadId": "930b6daf-cfd3-4d74-ab47-a4d29e6ca850",
        "provinciaId": "fbcb8e55-e45c-4837-8ee1-eff2ce08de04",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "691d4052-72d4-4a4c-ba6e-a4d737706fba",
        "nombre": "Cutral",
        "codigoAeroplanta": "CUT",
        "validada": true,
        "ciudadId": "9D44247C-C4C5-4C44-A0DC-43CE486EF001",
        "provinciaId": "EF69144E-F754-4DD7-A3CA-8FD77FA7D185",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "ee32997a-7b9f-4451-a4f8-bbc4332205e5",
        "nombre": "Formosa",
        "codigoAeroplanta": "FMA",
        "validada": true,
        "ciudadId": "28fd880a-044a-4af8-9814-e6260e29639a",
        "provinciaId": "07950603-ed8a-4485-99a8-be30ead60bbe",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "c4608995-2c4d-45aa-94ae-be4bcfe2999d",
        "nombre": "Rio Gallegos",
        "codigoAeroplanta": "RGL",
        "validada": true,
        "ciudadId": "C40DD61D-F1DD-44AF-9292-65BEB7ECCCC6",
        "provinciaId": "CEA0FBB0-27EA-4133-9A25-A7834A7C03C9",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "495d8c74-1201-4562-8748-42303cef80f4",
        "nombre": "Villa Reynolds",
        "codigoAeroplanta": "VRE",
        "validada": true,
        "ciudadId": "57BAD566-C3C8-43A3-BAC4-BC658A93672C",
        "provinciaId": "C86E5EBF-0B2F-4990-927B-E63227F2AA15",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "efbbbc2d-2adc-4216-80d6-b731afe85ffb",
        "nombre": "Ezeiza",
        "codigoAeroplanta": "EZE",
        "validada": true,
        "ciudadId": "0C646F19-3BEA-4544-8CF0-CC452450F8FC",
        "provinciaId": "44A8954C-4A74-4C07-B022-100000000001",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "c300e468-4cda-4206-8792-e82a799768ee",
        "nombre": "Chapelco",
        "codigoAeroplanta": "CPC",
        "validada": true,
        "ciudadId": "2a3f2b63-7e81-43b9-be52-d210ecb1f96c",
        "provinciaId": "ef69144e-f754-4dd7-a3ca-8fd77fa7d185",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "cd6cd567-a993-4ce2-b2ec-c5c65cfa69f9",
        "nombre": "Rio Cuarto",
        "codigoAeroplanta": "RCU",
        "validada": true,
        "ciudadId": "AD11A241-9A3E-48B7-890F-A19EA40617DC",
        "provinciaId": "8112761A-9CFA-47FB-BF45-76488399C9E9",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "e17bc077-ccb8-4b28-9ea2-3cea1aafc9b9",
        "nombre": "San Fernando",
        "codigoAeroplanta": "FDO",
        "validada": true,
        "ciudadId": "238f0977-cda0-4149-aa72-c6d5de5861a3",
        "provinciaId": "44a8954c-4a74-4c07-b022-100000000001",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "0f051ebe-818a-48b6-ae82-cba0eb6aacc5",
        "nombre": "San Juan",
        "codigoAeroplanta": "UAQ",
        "validada": true,
        "ciudadId": "62627940-e29a-47e1-ae7a-e89e3fe90234",
        "provinciaId": "2e8183ac-7ed3-4f82-b3e6-7ef605105641",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "e4e61bc3-8e14-44d0-9817-e0aa9112b714",
        "nombre": "Comodoro Rivadavia",
        "codigoAeroplanta": "CRD",
        "validada": true,
        "ciudadId": "AAFB57EB-1AC2-40D7-8BCD-580737A0B072",
        "provinciaId": "D0A3F374-52F5-4172-B448-71FA522A8171",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "ac781a58-7d6a-4c22-875a-93a62349eeeb",
        "nombre": "Palomar",
        "codigoAeroplanta": "EPA",
        "validada": true,
        "ciudadId": "78845c10-6b0c-41de-8eac-a160147ecd2e",
        "provinciaId": "44a8954c-4a74-4c07-b022-100000000001",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "6cbd00b8-c0ea-499d-9745-3da68de4b718",
        "nombre": "Paraná",
        "codigoAeroplanta": "PRA",
        "validada": true,
        "ciudadId": "488EF581-F2A6-466C-B2C9-7D3BE427BA6F",
        "provinciaId": "7DC36CE8-749C-4B51-B3C9-F41E81F14FBC",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "d4d95e63-dfea-4dc8-afa6-2466dd151fc5",
        "nombre": "La Rioja",
        "codigoAeroplanta": "IRJ",
        "validada": true,
        "ciudadId": "4ec30233-7133-4a15-8899-661f85e08176",
        "provinciaId": "d16034f2-49a4-4fad-a4a4-25d517852108",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "d7de7015-266e-41ad-8e7a-efae894766e5",
        "nombre": "Concordia",
        "codigoAeroplanta": "COC",
        "validada": true,
        "ciudadId": "cf718490-e48c-4028-8a61-864d04b52c6a",
        "provinciaId": "7dc36ce8-749c-4b51-b3c9-f41e81f14fbc",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "249e6414-a439-45ee-baaf-90c8ba29a83f",
        "nombre": "Escuela de Aviación",
        "codigoAeroplanta": "ESC",
        "validada": true,
        "ciudadId": "C53BD4B6-E14A-42E5-8722-77E4199E8BAB",
        "provinciaId": "8112761A-9CFA-47FB-BF45-76488399C9E9",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "7811c6be-9bc3-4c05-9047-fe8b087bfd40",
        "nombre": "Santa Rosa",
        "codigoAeroplanta": "RSA",
        "validada": true,
        "ciudadId": "e99f4d5b-7987-4553-bf56-51c5fe5948e7",
        "provinciaId": "14cc219b-ddb5-4857-b4ee-f5a08ea9c690",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "f74b073d-cc13-4934-9bb6-57540cffbfc7",
        "nombre": "Salta",
        "codigoAeroplanta": "SLA",
        "validada": true,
        "ciudadId": "85804bcf-236c-41c2-8f80-386c76f03606",
        "provinciaId": "5f41c74e-bfba-47ab-8842-c99fcb1a2a0f",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "24be5a22-595d-43d6-99b0-0e3a5aec42fa",
        "nombre": "Iguazu",
        "codigoAeroplanta": "IGR",
        "validada": true,
        "ciudadId": "59f6c925-7c58-4a03-becf-6e412adf2389",
        "provinciaId": "bd7af542-f1f0-4643-8830-34931f4716c8",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "fc705280-fcfe-40f3-be50-f7718c37b75c",
        "nombre": "Morón",
        "codigoAeroplanta": "MOR",
        "validada": true,
        "ciudadId": "6bcb9b96-2129-4339-aa81-6be71bfc926d",
        "provinciaId": "44a8954c-4a74-4c07-b022-100000000001",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "4b51a6d5-419b-4cae-860d-feb8bea034be",
        "nombre": "Sauce Viejo",
        "codigoAeroplanta": "SFN",
        "validada": true,
        "ciudadId": "C726B17E-A469-4DFD-A0CE-685031609E39",
        "provinciaId": "FBCB8E55-E45C-4837-8EE1-EFF2CE08DE04",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "aeeca7bf-302f-4635-ab90-13eb1946edb5",
        "nombre": "Malargue",
        "codigoAeroplanta": "LGS",
        "validada": true,
        "ciudadId": "1966c07e-6c4a-490a-aacc-079b9236d24d",
        "provinciaId": "970175ce-1160-44e6-9a47-e8ed4bfbe321",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "b60ade01-2cbe-4bac-81a1-ea705ae6fa29",
        "nombre": "Puerto Madryn",
        "codigoAeroplanta": "PMY",
        "validada": true,
        "ciudadId": "05d75833-a01b-4ebf-b1c3-b15b01a9580b",
        "provinciaId": "d0a3f374-52f5-4172-b448-71fa522a8171",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "8343c485-4522-4a4b-908c-e17a179e6e90",
        "nombre": "Directo 1",
        "codigoAeroplanta": "DI1",
        "validada": true,
        "ciudadId": "BCBE189D-0721-4B4C-A615-04FFF9F481F7",
        "provinciaId": "44A8954C-4A74-4C07-B022-100000000001",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "b63ba3d8-b6b0-4dc0-8ad8-aa73bd43dfb6",
        "nombre": "Mariano Moreno",
        "codigoAeroplanta": "ENO",
        "validada": true,
        "ciudadId": "9211E61F-2BED-4D95-A55E-A5CE71235D53",
        "provinciaId": "44A8954C-4A74-4C07-B022-100000000001",
        "soloPAD": false,
        "habilitarFacturacion": false
      },
      {
        "id": "46990fa6-58c0-4466-835b-5871f41bef94",
        "nombre": "Ushuaia",
        "codigoAeroplanta": "USH",
        "validada": true,
        "ciudadId": "434AFD45-48A9-4742-A3FF-7B2764A707F8",
        "provinciaId": "80759406-9388-4D3C-8041-DAF96DC4F3C2",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "10b62173-dc7a-47e1-8880-745dc4f7f141",
        "nombre": "Corrientes",
        "codigoAeroplanta": "CNQ",
        "validada": true,
        "ciudadId": "96cab8e8-b9ea-4c62-8eb3-0f87b0a3f889",
        "provinciaId": "c808cc4d-167d-4d97-87ff-725894863269",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "8b2ab7de-f920-4a9f-b098-663bb686b15d",
        "nombre": "San Luis",
        "codigoAeroplanta": "LUQ",
        "validada": true,
        "ciudadId": "C22B1001-ADD7-4B6D-9D54-7A7669F662C4",
        "provinciaId": "C86E5EBF-0B2F-4990-927B-E63227F2AA15",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "54137732-2c5e-4bb2-abda-ff985f24d0e6",
        "nombre": "Catamarca",
        "codigoAeroplanta": "CTC",
        "validada": true,
        "ciudadId": "674cd6c5-2341-4d36-bd3b-ce1ab7f8e396",
        "provinciaId": "0f6aff6a-8a87-4720-9a1f-993d2b0d1e77",
        "soloPAD": true,
        "habilitarFacturacion": false
      },
      {
        "id": "da3671df-00d3-46d8-b4f2-f2d46a94bfd6",
        "nombre": "Posadas",
        "codigoAeroplanta": "PSS",
        "validada": true,
        "ciudadId": "51966818-45B1-429A-A1A3-34CF88D8F237",
        "provinciaId": "BD7AF542-F1F0-4643-8830-34931F4716C8",
        "soloPAD": false,
        "habilitarFacturacion": false
      }
    ]
  }
}