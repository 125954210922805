import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../core/authentication/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  enabledButton: boolean = true;
  constructor(private authService: AuthService, private spinner: NgxSpinnerService) {}

  login() {
    if(this.enabledButton) {
      this.spinner.show();
      this.authService.login();
      this.enabledButton = false;
      setTimeout(() => {
        this.enabledButton = true;
      }, 3000);
    }    
  }
}


