import { Injectable, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../shared/models/despacho/Usuario';
import { environment } from 'src/environments/environment';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { tap } from 'rxjs/operators';
import { Aeroplanta } from '../shared/models/despacho/Aeroplanta';
import { TipoUsuario } from '../shared/enums/tipoUsuario';
import { UsuarioAccion } from '../shared/models/login/UsuarioAccion';
import { UsuarioAeroplanta } from '../shared/models/abm/UsuarioAeroplanta';
import { BehaviorSubject, Observable } from 'rxjs';
import { Rol } from '../shared/models/notificaciones/Rol';
import { SharedService } from '../shared/shared.service';

@Directive()
@Injectable({ providedIn: 'root' })

export class UsuarioService {

  baseUrl = environment.apiServer.baseUrl;
  private msUsuarioUrl = environment.apiServer.usuariosBaseUrl; 

  private handleError: HandleError;
  private currentUser: Usuario;
  public aeroplantas: Aeroplanta[];
  private listaDeRoles: TipoUsuario[] = [TipoUsuario.OPERADOR, TipoUsuario.SUPERVISOR, TipoUsuario.JEFEDEAEROPLANTA, TipoUsuario.TUTOR, TipoUsuario.MANDATARIO, TipoUsuario.OPERADOR_3, TipoUsuario.OPERADORDIRECTO, TipoUsuario.APPSTORE];
  private listaDeRolesComerciales: TipoUsuario[] = [TipoUsuario.COMERCIAL, TipoUsuario.COMERCIAL_AV, TipoUsuario.COMERCIAL_LN];

  private _usuarioSource = new BehaviorSubject<Usuario>(null);
  usuario$ = this._usuarioSource.asObservable();

  constructor(private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private sharedService: SharedService) {
    this.currentUser = new Usuario();
    this.handleError = httpErrorHandler.createHandleError('Usuarios');
  }

  getAeroplantaActual() {
    return this.currentUser.ultimaAeroplanta;
  }

  getNombresOperador() {
    return this.currentUser.nombresCompletos;
  }
  
  getTipoUsuario() {
    return TipoUsuario[this.currentUser.tipoUsuario];
  }

  getMostrarRoles() {
    return this.currentUser.sRoles;
  }

  getIdUsuario() {
    return this.currentUser.id;
  }

  getUsername() {
    return this.currentUser.userName;
  }

  getUsernameByName() {
    return this.currentUser.nombresCompletos;
  }

  getRoles() {
    return this.currentUser.roles;
  }

  //Envia los roles que pueden emitir aerovales
  getRolesEmitirAerovales(){
    return this.listaDeRoles;
  }

  getRolesComerciales(){
    return this.listaDeRolesComerciales;
  }

  getDniOperador(){
    return this.currentUser.dni;
  }

  getEmailUsuario(){
    return this.currentUser.email ? this.currentUser.email : "-";
  }

  getNombresCompletosUsuario(){
    return this.currentUser.nombresCompletos ? this.currentUser.nombresCompletos : this.currentUser.nombresCompletos;
  }

  getDniUsuario(){
    return this.currentUser.dni ? this.currentUser.dni : "-";
  }

  loginUsuario(usuario){
    return this.http.post<UsuarioAccion>(this.msUsuarioUrl + '/UsuariosV1/LoginUsuario', usuario)
    .pipe(
      tap(usuarioAccion => {                
        this.currentUser = usuarioAccion.usuarioDTO;

        this.currentUser.roles = [5, 1];
        this.currentUser.aeroplantas = this.sharedService.aeroplantas;
        
        // Aqui vuelven los roles. Tomarlos y asignarlos a usuario.
        this.currentUser.sRoles = this.convertirRolesIntEnRolesString(this.currentUser.roles);
        var aeroplantasFiltradas = this.currentUser.aeroplantas.filter(a => { return a.validada; });;
     
        this.aeroplantas  = aeroplantasFiltradas.map(item => {
          var aep: Aeroplanta = new Aeroplanta();
      
          aep.id = item.id;
          aep.nombre = item.nombre;
          aep.codigoAeroplanta = item.codigoAeroplanta; 
          aep.ciudadId = item.ciudadId;    
          aep.provinciaId  = item.provinciaId;
          aep.soloPAD = item.soloPAD;
          aep.habilitarFacturacion = item.habilitarFacturacion;
      
          return aep;
        });

        this.currentUser.ultimaAeroplanta = this.aeroplantas[0];

        this._usuarioSource.next(this.currentUser);
      }
    ));
  }
  
  obtenerTodosLosUsuarios(soloActivos: boolean = true, conAeroplantas: boolean = true){
    ///api/UsuariosV1/Todos?SoloActivos=true&ConAeroplantas=false

    return this.http.get<Usuario[]>(this.msUsuarioUrl + "/UsuariosV1/Todos?SoloActivos="+soloActivos+"&ConAeroplantas="+conAeroplantas) 
    .pipe(
      tap(usuarios => {
        console.log(usuarios);
      })
    );
  }

  obtenerTodosLosUsuariosPromise(){
    return this.http.get<Usuario[]>(this.msUsuarioUrl + "/UsuariosV1/Todos").toPromise();
  }
  
  obtenerUsuariosPendientesValidacion(codigoAeroplanta){
    return this.http.get<Usuario[]>(this.msUsuarioUrl + '/UsuariosV1/UsuariosPendientes/' + codigoAeroplanta)
    .pipe(
      tap(usuarios => {
        console.log(usuarios);
      })
    );
  }

  obtenerUsuariosConRoles(roles: number[], codigoAeroplanta?: string): Promise<Usuario[]> {
    return this.http.put<Usuario[]>(this.msUsuarioUrl + '/UsuariosV1/Roles?listaRoles=' + roles + '&codigoAeroplanta=' + codigoAeroplanta, roles).toPromise();
  }

  aysncObtenerComercialPorAeroplanta(aeroplanta: string): Promise<Usuario>{
    return this.http.get<Usuario>(this.msUsuarioUrl + '/UsuariosV1/' + aeroplanta + '/Comercial').toPromise();
  }

  setAeroplanta(codigoAeroplanta: string) {
    console.log('aeroplanta id elegida:' + codigoAeroplanta);    
    this.currentUser.ultimaAeroplanta = this.aeroplantas.find(a => a.codigoAeroplanta == codigoAeroplanta);
    this.sharedService.aeroplantaSeleccionadaData=true;    
  }

  setAeroplantaInicial(aeroplanta: Aeroplanta){       
    this.currentUser.ultimaAeroplanta = aeroplanta;
    this.sharedService.aeroplantaSeleccionadaData=true;
  }

  asignarUsuarioAeroplanta(usuario, aeroplanta: Aeroplanta){

    var usuarioAeroplanta = new UsuarioAeroplanta();
    usuarioAeroplanta.nombre = aeroplanta.nombre;
    usuarioAeroplanta.id = aeroplanta.id;
    usuarioAeroplanta.codigoAeroplanta = aeroplanta.codigoAeroplanta;
    usuarioAeroplanta.validada = true;
    usuarioAeroplanta.soloPAD = aeroplanta.soloPAD;
    usuarioAeroplanta.habilitarFacturacion = aeroplanta.habilitarFacturacion;

    let aeroplantas : UsuarioAeroplanta[] = [usuarioAeroplanta];
    
    return this.http.post<UsuarioAccion>(this.msUsuarioUrl + '/UsuariosV1/AsignarAeroplantas/' + usuario.id + "?userDNI=" + usuario.dni, aeroplantas)
    .pipe(
      tap(usuarioAccion => {
        console.log(usuarioAccion);
      })
    );
  }

  asignarUsuarioAeroplantas(userId,  dni, aeroplantas: Aeroplanta[], validada){

    let usuarioAeroplantas : UsuarioAeroplanta[] = this.armarArrayUsuarioAeroplanta(aeroplantas, validada);

    return this.http.post<UsuarioAccion>(this.msUsuarioUrl + '/UsuariosV1/AsignarAeroplantas/' + userId + "?userDNI=" + dni, usuarioAeroplantas)
    .pipe(
      tap(usuarioAccion => {
        console.log(usuarioAccion);
      })
    );
  }

  eliminarUsuarioDeAeroplanta(userId, aeroplanta: Aeroplanta){
    return this.http.delete<String>(this.msUsuarioUrl + '/UsuariosV1/DesasignarAeroplanta?codigoAeroplanta=' + aeroplanta.codigoAeroplanta + '&userId=' + userId)
    .pipe(
      tap(response => {
        console.log("DELETE RESPONSE: ", response);
      })
    );
  }

  armarArrayUsuarioAeroplanta(aeroplantas: Aeroplanta[], validada){
    
    var usuariosAeroplantas = [];

    for (var i = 0; i < aeroplantas.length; i++) {
      var usuarioAeroplanta = new UsuarioAeroplanta();
      usuarioAeroplanta.nombre = aeroplantas[i].nombre;
      usuarioAeroplanta.id = aeroplantas[i].id;
      usuarioAeroplanta.codigoAeroplanta = aeroplantas[i].codigoAeroplanta;
      usuarioAeroplanta.validada = validada;
      usuarioAeroplanta.soloPAD = aeroplantas[i].soloPAD;
      usuarioAeroplanta.habilitarFacturacion = aeroplantas[i].habilitarFacturacion;

      usuariosAeroplantas.push(usuarioAeroplanta);
    }

    return usuariosAeroplantas;
  }

  getUsuariosPorRol(rol:number):Observable<Usuario[]>{
    return this.http.get<Usuario[]>(this.msUsuarioUrl+"/UsuariosV1?rol="+rol);
  }

  getUsuariosPorRolYAeroplanta(rol:number, aeroplanta: string, todoTipoComercial: boolean):Observable<Usuario[]>{
    return this.http.get<Usuario[]>(this.msUsuarioUrl + '/UsuariosV1?rol=' + rol + '&codigoAeroplanta=' + aeroplanta + '&todoTipoComercial='+todoTipoComercial);
  }

  obtenerComercialPorAeroplanta(aeroplanta: string): Observable<Usuario>{
    return this.http.get<Usuario>(this.msUsuarioUrl + '/UsuariosV1/' + aeroplanta + '/Comercial');
  }

  convertirRolesIntEnRolesString(intRoles : number[]){
    var sRoles = "";

    for(let i = 0; i < intRoles.length; i++){
      var iRole = intRoles[i];
      var sRole = TipoUsuario[iRole];
      sRoles += sRole + ", ";
    }

    return sRoles.substring(0, sRoles.length - 2);
}

  getArrayStringRoles(){
    return this.currentUser?.sRoles?.split(", ");
  }

  obtenerTodosLosRoles():Observable<Rol[]>{
    return this.http.get<Rol[]>(this.msUsuarioUrl + "/RolesV1");
  }

  obtenerTodosLosRolesPromise():Promise<Rol[]>{
    return this.http.get<Rol[]>(this.msUsuarioUrl + "/RolesV1").toPromise();
  }

  getAeroplantasCurrentUser() {
    return this.currentUser?.aeroplantas;
  }

}
