import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service';
import { MessageService } from 'src/app/shell/message.service';
import { environment } from 'src/environments/environment';
import { ErrorModel } from 'src/app/shared/models/ErrorModel';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    private messageService: MessageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      console.log('Error Interceptor => ', err);
      /*if (err.url == environment.authentication.userinfoEndpoint) {
        console.log("Error getUserInfo");
        this.authenticationService.signout();
        return throwError(err.error);
      }*/
      if (err.status == 0) {
        // Se devuelve un ErrorModel para que no falle el ErrorHandler: 
        // TypeError: Cannot read property 'description' of null
        return throwError(new ErrorModel('0',
          'Se ha producido un error al conectarse con backend',
          'Se ha producido un error al conectarse con backend'));
      }
      if (err.status === 401) {
        this.messageService.showErrorMessage("Se ha producido un error con la autenticación, intente cerrar la sesión y vuelva a autenticarse");
        // Se devuelve un ErrorModel para que no falle el ErrorHandler: 
        // TypeError: Cannot read property 'description' of null
        return throwError(new ErrorModel('0','Unauthorized','Unauthorized'));
      }
      if (err.error && err.error.code) {
        return throwError(new ErrorModel(err.error.code, err.error.description, err.error.message));
      }
      return throwError(err);
    }))
  }
}
