import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  isAuthenticated: boolean;
  userInfo: any;

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.authService.getUserInfo().subscribe((data) => {
      console.log('entro: ', data);
      this.userInfo = data
      this.isAuthenticated = data != null ? true : false;
      this.authService.processLogin(this.userInfo);
    }, (error) => {
      console.error('Error fetching user info:', error)  
    }); 
  }
}
