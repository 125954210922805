export const environment = {
    name: 'dev_electrica',  
    production: true,
    electrica: true,    
    logging: {
      console: true,
      appInsights: false
    },
    apiServer: {
      baseUrl: 'https://oidc-dev.electrica.ar/api',
      ranchosBaseUrl: 'https://oidc-dev.electrica.ar/api',
      tradersBaseUrl: 'https://oidc-dev.electrica.ar/api',
      facturacionBaseUrl: 'https://oidc-dev.electrica.ar/api',
      stockBaseUrl: 'https://oidc-dev.electrica.ar/api',
      reportesUrl: 'https://oidc-dev.electrica.ar/api',
      productosUrl:'https://oidc-dev.electrica.ar/api',
      clientesBaseUrl:'https://oidc-dev.electrica.ar/api',
      usuariosBaseUrl:'https://oidc-dev.electrica.ar/api',
      notificacionesBaseUrl: 'https://oidc-dev.electrica.ar/api',
      cierreTurnoBaseUrl: 'https://oidc-dev.electrica.ar/api',
    },
    signalR: {
      activarFuncionalidad: false,
      signalRUrl: 'https://comunicaciones-dev.electrica.ar/notify'
    },
    grafana: {
      preciosUtilizados: 'https://dev.electrica.ar/grafana/d/j2SbesSnz/precios-utilizados-dev?orgId=1&from=1600190535585&to=1631726535586&theme=light',
      reporteMovimientosProductos:'https://dev.electrica.ar/grafana/d/ZVfVF4A7z/reporte-movimiento-productos-stock-dev?orgId=1'
    },
    frontdoor: {
      timeout: 240000
    },
    bffUrl: 'https://oidc-dev.electrica.ar',
}